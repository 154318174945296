import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'

import Layout from '../layouts'

import { Link } from 'gatsby'

import '../scss/article.scss'

import defaultNewsImage from '../assets/images/news-default-image.jpg'

export default function Template({
  data,
}) {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout>
      <div className="article event">

        <Helmet>
          <title>DigiWells – {frontmatter.title}</title>
          <meta property="og:title" content={`DigiWells – ${frontmatter.title}`} />
          <meta name="description" content={frontmatter.short_description ? frontmatter.short_description : ''} />
          <meta property="og:description" content={frontmatter.short_description ? frontmatter.short_description : ''} />
          <meta property="og:type" content="article" />
          {
            /*
            <meta name="image" content={image} />
            <meta property="og:image" content={image} />
            */
          }

          {
            frontmatter.banner_image &&
            frontmatter.banner_image !== '' &&
              <meta property="image" content={frontmatter.banner_image} />
          }

          {
            frontmatter.banner_image &&
            frontmatter.banner_image !== '' &&
              <meta property="og:image" content={frontmatter.banner_image} />
          }

          {
            (!frontmatter.banner_image || frontmatter.banner_image !== '') &&
              <meta property="image" content={defaultNewsImage} />
          }

          {
            (!frontmatter.banner_image || frontmatter.banner_image !== '') &&
              <meta property="og:image" content={defaultNewsImage} />
          }

        </Helmet>

        <div className="container container-10">
          <div className="pre"><Link to="/events/">Go back to all events</Link></div>
          <h1 className="page-title">{frontmatter.title}</h1>

          {
            frontmatter.banner_image &&
            <div className="article-banner">
              <img src={frontmatter.banner_image} alt=""/>
            </div>
          }

          <div className="article-inner">
            <div className="article-text">
              <div
                className="blog-post-content"
                dangerouslySetInnerHTML={{ __html: html }}
              />
            </div>
          </div>
        </div>

      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        short_description
        banner_image
        list_image
      }
      fields {
        slug
      }
    }
  }
`
